<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Acuerdo de privacidad</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/agorarecursos/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales (“LFDPPP”) y las normas y lineamientos de política de privacidad pertinentes. Esta Política de Privacidad se publica en los siguientes términos:

1. Fines terapéuticos necesarios
    Requerimos los datos personales antes mencionados para las siguientes finalidades:
i.Identificar a la persona que solicita algún producto;
ii.Contiene los elementos necesarios para realizar un análisis que determine la capacidad del solicitante para proporcionar el producto solicitado.
iii. Preparar comentarios personales sobre solicitudes de productos.
iv.Crear y registrar usuarios en la aplicación informática.
v. Desarrollar contratos para los productos necesarios.
vi. Cumplir con las leyes, regulaciones y requisitos legales aplicables.

2. Metas secundarias
i. Realizar actividades de marketing, publicidad, promoción y telemercadeo a través de cualquier medio. y/o proporcionar productos y/o servicios electrónicamente

3. Un mecanismo mediante el cual los propietarios pueden expresar comentarios negativos con fines secundarios.
Si no desea que sus datos personales sean tratados con fines secundarios a partir de ahora, puede informarnos por correo electrónico como se indica anteriormente. Especifica el nombre del propietario y la información de contacto. e incluir identificación oficial.La objeción al uso de su información personal para estos fines no será motivo para que nos neguemos a brindarle los servicios y productos que haya solicitado o contratado con nosotros.

4. Transferencia de información personal
El uso de Datos Personales por parte del Titular estará controlado y restringido por esta Política de Privacidad. Dicha información se conservará durante el plazo previsto en las disposiciones legales pertinentes.
1. Para cumplir con la finalidad principal especificada en el artículo 3 de esta Política de Privacidad. De conformidad con el artículo 37 de esta Ley, usted podrá transferir sus datos sin su consentimiento expreso para:
a.Para entidades controladoras, subsidiarias o afiliadas...
B. Autoridades judiciales, gubernamentales, monetarias o fiscales para cumplir con las disposiciones legales aplicables y aclarar asuntos o ejercer cualesquiera derechos.
C. Una compañía de seguros diseñada para tener pólizas de seguro de desempleo, invalidez o muerte. Esta información solo se compartirá cuando finalice la cobertura.
D. Compañía de información crediticia
II.El Titular autoriza la transferencia de Datos Personales a terceros proveedores de servicios nacionales e internacionales que nos apoyen en cualquier proceso, estrictamente con el fin de verificar directa o indirectamente su identidad, evaluación de riesgos e historial crediticio durante el procesamiento. Firmar un contrato y posteriormente realizar mejoras.
3. Agencias de cobranza: ejercen los derechos derivados del contrato de préstamo entre los propietarios. Los datos personales de este último serán transferidos a la institución recaudadora con la que se celebre el contrato de gestión de cuenta. En todo caso, los datos personales transferidos son los estrictamente necesarios para el ejercicio del derecho a recibir el pago. Específicamente, incluirá la información de contacto del prestatario y cualquier monto adeudado como resultado del acuerdo de préstamo con el dueño de la propiedad.
4. Sobre la recogida de datos biométricos Si la contratación, tratamiento, protección y gestión de los productos del proveedor requiere la identificación del tercero o del responsable, dichos datos serán cedidos al tercero o al responsable.
Si se transfiere a un tercero nacional o extranjero. Garantiza que el destinatario respetará los datos expresamente facilitados para las finalidades de tratamiento indicadas. Estos terceros pueden dedicarse a actividades similares o pueden estar relacionados entre sí a través de relaciones contractuales. La información anterior incluye, entre otros, departamentos y agencias gubernamentales. Empresas de facturación Instituciones financieras (nacionales o internacionales) Instituciones de seguros y/o fianzas y personas naturales y jurídicas que presten servicios Estas transferencias tienen como objetivo:
Cumplir con las leyes relativas a la protección de la información personal en poder del sector privado.
B. Fuente correcta Mantener y/o cumplir las obligaciones y derechos pactados mediante el contrato suscrito por ambas partes.
C. Garantía y protección de los derechos de pago
D. Garantías de toda clase de derechos contractuales;
y Para proteger los derechos del propietario.
Para los fines descritos en el último párrafo. Nos comunicaremos con el Titular para darle la oportunidad de expresar sus objeciones al uso y procesamiento de sus Datos Personales.
Salvo en los casos anteriormente delimitados, el titular deberá consentir expresamente la transferencia de sus datos personales.

5. Comprender los procedimientos y mecanismos para cambios en la política de privacidad.
Esta Política de Privacidad podrá ser revisada, modificada o actualizada en respuesta a nuevos requisitos legales. Demanda por los productos o servicios que ofrecemos, nuestras prácticas de privacidad, cambios en nuestro modelo de negocio, o por cualquier otro motivo sujeto a las condiciones anteriores. El propietario está obligado a visitar este sitio web periódicamente para comprobar si hay cambios en este aviso.

6. De acuerdo
Al registrarse en nuestra aplicación móvil el titular consiente el procesamiento de sus datos personales de acuerdo con los términos de esta política de privacidad.

7. Información de contacto
Si tiene alguna pregunta sobre el contenido, interpretación o alcance de esta Política de Privacidad. Por favor envíanos un email.`,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 2200px;
  background-color: #FAFCFF;
  border-radius: 32px;
  margin: auto;
  margin-top: -190px;
  transform: scale(87%,95%);
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
</style>